<template>
  <b-form v-on:submit.prevent="onSubmit">
    <t-preloader :loading="loading" color="#00a29c"></t-preloader>
    <b-row>
      <b-col>
        <label for="code">{{ $t('label_opinionSurvey_intro_pleaseEnterCode')}}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col offset-md="3" md="6" sm="12">
        <b-form-input id="code" type="text" v-model="form.code" autocomplete="off" required></b-form-input>
      </b-col>
    </b-row>
    <b-row v-if="error">
      <b-col>
        <p class="error">{{ $t('label_opinionSurvey_error_' + error)}}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn type="submit" variant="secondary">{{ $t('label_opinionSurvey_sendCode_button')}}</b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <img class="mx-auto d-block"  style="height: 300px;" :src="'/static/images/UNPD/unpd_logo_color_'+lang+'.svg'">
      </b-col>
    </b-row>
  </b-form>
</template>


<script>
import { mapGetters } from 'vuex'
import * as api from '../api/api'

export default {
  instruction: 'Home',
  data: function () {
    return {
      loading: false,
      form: {
        code: ''
      },
      error: ''
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    ...mapGetters([
      'getOpinionSurvey',
      'getTemplateId'
    ])
  },
  methods: {
    onSubmit: function () {
      this.loading = true
      this.error = ''
      var data = {
        snapshotId: this.$route.params.snapshot_id,
        token: this.form.code,
        templateId: this.getTemplateId
      }
      this.$store.dispatch('INVITATIONS_GET', data).then((resp) => {
        this.loading = false
        if (resp.languageIdentifier) {
          this.$store.dispatch('setLangNew', resp.languageIdentifier)
        }
        if (resp.status === 'complete' || resp.status === 'expired') {
          this.error = resp.status
        } else if (resp.status === 'new') {
          this.loading = true
          api.apiCall({
            url: 'Surveys/createSurvey/' + this.$route.params.snapshot_id + '/' + this.form.code + '/' + data.templateId,
            method: 'POST',
            data: data
          }).then(() => {
            this.$router.push('/welcome')
          }).catch((err) => {
            console.log(err)
            this.loading = false
            this.error = err.response.status
          })
        } else {
          this.$router.push('/welcome')
        }
      }).catch((err) => {
        this.loading = false
        this.error = err.response.status
      })
    }
  },
  created () {
    this.$store.dispatch('CLEAR_STORE')
    this.$store.dispatch('SET_TEMPLATE_ID', this.$route.query.s || 'OpinionSurvey')
  }
}
</script>

<style lang="scss" scoped>
  /* components/template/Code.vue */
@import "../assets/css/variables.scss";
.error {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 1.2rem;
  color: $primary-color;
}
.btn {
  margin-top: 15px;
}

#code{
  border-radius:10px;
  border-width: 0.5px;
  border-color: $primary-color;
  background-color: #f4fafd;
}
</style>

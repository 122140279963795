// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto'

import Vue from 'vue'
import router from './router'
import i18n from './lang/lang'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App'

// template
import MoonLoader from 'vue-preload-spinner/src/MoonLoader'

import '@/components/template/_globals'

Vue.component('t-preloader', MoonLoader)

Vue.use(BootstrapVue)

/* eslint-disable no-new */
export const app = new Vue({
  store,
  i18n,
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  },
  render: h => h(App),
}).$mount('#app')

window['vue'] = app
window.store = store

import axios from 'axios'
import {getApiUrl} from "@/utils/urlConfig";


axios.defaults.baseURL =  getApiUrl()
// axios.defaults.baseURL = ''
axios.defaults.headers.post['Content-Type'] = 'application/json'
//axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token') || ''

export const apiCall = ({ url, method, ...args }) => new Promise((resolve, reject) => {
  axios({

    method: method || 'GET',
    url: url,
    data: args.data
  }).then(resp => {
    resolve(resp)
  }).catch((error) => {
    console.log(error.response)
    reject(error)
  })
})

import Vue from 'vue'
import Router from 'vue-router'
// Containers
import Survey from '@/containers/Survey'
import Base from '@/containers/Base'

// Components
import Home from '@/components/Home'
import Code from '@/components/Code'
import Invitation from '@/components/Invitation'
import Welcome from '@/components/Welcome'
import Send from '@/components/Send'
import store from '../store'
import QuestionSelect from '@/components/QuestionSelect'
import axios from "axios";
import {getApiUrl, getSurveyUrl} from "@/utils/urlConfig";

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.getToken) {
    next()
    return
  }
  next('/')
}

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/survey/answer',
      name: 'Survey',
      component: Survey,
      beforeEnter: ifAuthenticated,
      meta: {
        step: {
          backUrl: 'welcome',
          nextUrl: '/send'
        }
      },
      children: [
        { name: 'QuestionSelect',
          path: ':question',
          component: QuestionSelect
        },
        { name: 'QuestionSelect',
          path: '',
          redirect: '0'
        }
      ]
    },
    {
      path: '/',
      name: 'Base',
      component: Base,
      children: [
        {
          name: 'Home',
          path: '',
          component: Home
        },
        {
          path: 'welcome',
          name: 'Welcome',
          component: Welcome,
          beforeEnter: ifAuthenticated
        },
        {
          name: 'send',
          path: 'send/:email',
          component: Send,
          beforeEnter: ifAuthenticated
        },
        {
          path: ':snapshot_id',
          name: 'Code',
          component: Code
        },
        {
          path: ':snapshot_id/:token_invitation_mail',
          name: 'Invitation',
          component: Invitation
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }

  ]
})

router.beforeEach((to, from, next) => {
  /* const lang = to.params.lang
  loadLanguageAsync(lang).then(() => next()) */

  console.log('getLang: ', store.getters.getLang)
  console.log('Domain: ', window.location.hostname)

  // set api url
console.log(getSurveyUrl('en'),getSurveyUrl('es'),getSurveyUrl('tr'), 'includes',window.location.hostname)

  if (getSurveyUrl('es').includes(window.location.hostname)) {
    axios.defaults.baseURL = getApiUrl('es') // 'encuesta.selloigualdadgenero.org'
  } else if (getSurveyUrl('tr').includes(window.location.hostname)) {
    axios.defaults.baseURL = getApiUrl('tr') // 'survey.genderequalityseal.org'
  } else {
    axios.defaults.baseURL = getApiUrl('en') // 'survey.genderequalityseal.org'
  }

  if (store.getters.getLang === 'labels') {
    if (getSurveyUrl('es').includes(window.location.hostname)) {
      store.dispatch('setLangNew', 'es').then(() => next()) // 'survey.genderequalityseal.org'
    } else if (getSurveyUrl('tr').includes(window.location.hostname)) {
      store.dispatch('setLangNew', 'tr').then(() => next()) // 'survey.genderequalityseal.org'
    } else {
      store.dispatch('setLangNew', 'en').then(() => next()) // 'encuesta.selloigualdadgenero.org'
    }
  } else {
    store.dispatch('setLangNew', store.getters.getLang).then(() => next())
  }
})


export default router

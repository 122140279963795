<template>
  <div class="wrapper">
    <t-preloader :loading="loading" color="#00a29c"></t-preloader>
    <div class="wrapper-header">
      <template-header/>
      <template-progress-bar :currentQuestion="questionNumber" :section="'label_'+getLower(invitation.templateId)+'_' + question.category + '_title'"/>
    </div>

<div class="questionContainer">
    <div v-if="questionNumber>0 && !warningMessage" class="buttonContainer">
      <b-btn class="navigation-buttons" @click="saveAndGo(-1)">
        <i class="fa fa-angle-double-left"></i>
      </b-btn>
    </div>
    <div class="questionContent"  v-if="!warningMessage">
      <transition name="fade" mode="out-in">
        <router-view :key="questionNumber"></router-view>
      </transition>
    </div>
  <transition name="fade" mode="out-in" leave-active-class="noDisplay">
  <div v-if="warningMessage" class="questionContainer warningMessage">
    <div class="question">
    <p class="instruction">{{$t('label_opinionSurvey_answerConfirmation')}}</p>
    <div class="options">
      <template>
        <label>
          <input name="is_required" type='radio' id="answer" value="answer" @change="changeWarning" />
          <div class='btn' for="answer">{{$t('label_opinionSurvey_answerConfirmation_iWantToAnswer')}}</div>

        </label>
        <label>
          <input name="is_required" type='radio' id="continue" value="continue" @change="changeWarning" />
          <div class='btn' for="continue">{{$t('label_opinionSurvey_answerConfirmation_continueWithoutAnswer')}}</div>

        </label>
      </template>
    </div></div>
  </div>
  </transition>
    <div v-if="!warningMessage" class="buttonContainer">
      <b-btn class="navigation-buttons" @click="saveAndGo(1)">
        <i class="fa fa-angle-double-right"></i>
      </b-btn>
    </div>
  </div>

  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  instruction: 'Survey',
  data: function () {
    return {
      step: {
        alias: '',
        index: 0
      },
      loading: true,
      questionNumber: parseInt(this.$route.params.question, 10),
      question: {}
    }
  },
  methods: {
    getLower (name) {
      return name.charAt(0).toLowerCase() + name.slice(1)
    },
    saveAndGo: function (direction) {
      this.$store.dispatch('OPINION_SURVEY_SAVE', this.getOpinionSurvey).then(() => {
        // this.$store.commit('orginizeSections')
        this.go(direction)
      })
    },
    go: function (direction, force = false) {
      let currQuestion = this.categoriesNew[this.questionNumber]
      if (currQuestion.fieldData && direction > 0 && !force) {
        var responses = currQuestion.fieldData.filter(s => { return s.value !== null })
        if (responses.length === 0) {
          this.$store.commit('setWarningMessage', true)
        }
      }
      if (currQuestion.isRequired && direction > 0 && !force) {
        if (currQuestion.value === '0' || currQuestion.value === 0 || !currQuestion.value) {
          this.$store.commit('setWarningMessage', true)
        }
      }
      if (!this.warningMessage) {
        var nextQ = 0
        if (direction > 0) {
          nextQ = this.getNextQuestionOpinionSurvey(currQuestion.field, currQuestion.value)
        } else {
          nextQ = this.getPreviousQuestionOpinionSurvey(this.questionNumber)
        }
        if (direction > 0) {
          if (nextQ !== -1) {
            this.questionNumber = nextQ
          } else {
            this.questionNumber++
          }
        } else {
          this.questionNumber = nextQ
        }
        this.question = this.categoriesNew[this.questionNumber]
        if (isNaN(nextQ) && direction > 0) {
          this.$router.push({name: 'send'})
        } else if (this.questionNumber > this.categoriesNew.length - 1) {
          this.$router.push({name: 'send'})
        } else {
          if (this.labelExistAndHasTranslation(this.question.title)) {
            this.$router.push({ name: 'QuestionSelect', params: { 'question': this.questionNumber } })
          } else {
            this.go(direction, true)
          }
        }
      }
    },
    labelExistAndHasTranslation (label) {
      return this.$te(label) && this.$t(label) && this.$t(label).length
    },
    changeWarning: function (event) {
      if (event.target.value === 'answer') {
        this.$store.commit('setWarningMessage', false)
      } else {
        this.$store.commit('setWarningMessage', false)
        var currQuestion = this.categoriesNew[this.questionNumber]
        if (!this.getOpinionSurvey.completed) {
          let os = this.getOpinionSurvey
          os[currQuestion.field] = 0
          this.$store.dispatch('OPINION_SURVEY_SAVE', os)
        }
        var nextQ = this.getNextQuestionOpinionSurvey(currQuestion.field, currQuestion.value)
        if (nextQ !== -1) {
          this.questionNumber = nextQ
        } else {
          this.questionNumber++
        }
        this.question = this.categoriesNew[this.questionNumber]
        if (isNaN(nextQ)) {
          this.$router.push({name: 'send'})
        } else if (this.questionNumber > this.categoriesNew.length - 1) {
          this.$router.push({name: 'send'})
        } else {
          if (this.labelExistAndHasTranslation(this.question.title)) {
            this.$router.push({ name: 'QuestionSelect', params: { 'question': this.questionNumber } })
          } else {
            this.go(1, true)
          }
        }
      }
    }
  },
  mounted () {
    if (isNaN(this.questionNumber)) {
      this.questionNumber = 0
    }
    this.question = this.categoriesNew[this.questionNumber]
    this.step = this.$route.meta.step
    this.$store.dispatch('OPINION_SURVEY_GET', this.invitation).then(() => {
      this.loading = false
    }).catch((err) => {
      console.log(err)
      this.loading = false
      this.$router.push('/')
    })
    this.$store.dispatch('OPINION_SURVEY_RULE_GET', this.categoriesNew).then(() => {
      console.log('Rules has been loaded')
    }).catch((err) => {
      console.log(err)
    })
  },
  watch: {
    data: {
      handler: function () {
        // TODO Save the info in the server
        // console.log(val)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      'categories': 'getCategories',
      'categoriesNew': 'getCategoriesNew',
      'currentQuestion': 'getCurrentQuestion',
      'progress': 'getProgress',
      'invitation': 'getInvitation',
      'getBusiness': 'getBusiness',
      'getNextQuestionOpinionSurvey': 'getNextQuestionOpinionSurvey',
      'getPreviousQuestionOpinionSurvey': 'getPreviousQuestionOpinionSurvey',
      'getOpinionSurvey': 'getOpinionSurvey',
      'warningMessage': 'getWarningMessage',
      'getSteps': 'getSteps'
    })
  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/variables.scss";
.question {
  font-family: $font-text;
  &.hide {
    display: none;
  }
}
.is_required {
  display: none;
  &.show {
    display: initial;
  }
}
.instruction {
  font-size: 1.5rem;
  font-family: $font-text;
  margin-bottom: 1rem;
  text-align: center;
  button {
    background: none;
    border: none;
    &:focus,
    &:active {
      outline: none;
    }
  }
}
.convention {
  font-size: 1.4rem;
  font-family: $font-text;
  text-align: left;
  padding-top: 20px;
  &.left {
    text-align: right;
  }
  .left-vertical {
    display: none;
  }
}

.options{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  input{
    display: none;
  }
  .manualI{
    font-size: 1.5rem;
    cursor: pointer;
    display: block;
    vertical-align: middle;
    border: 2px solid #00a29c;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
  }
  label{
    padding: 0;
    text-align: center;
    margin: 5px;
  }
  .btn{
    font-size: 1.5rem;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    border: 2px solid #00a29c;
    border-radius: 20px;
    &.completed{
      opacity: 0.65;
      color: #333;
      background-color: #ccc;
      border-color: #333;
      cursor: not-allowed;
    }
    &.completed{
      opacity: 0.65;
      color: #ccc;
      background-color: #333;
      border-color: #ccc;
    }
  }
}

html,
body,
.wrapper {
  height: 100%;
}

.wrapper-header {
  position: relative;
  z-index: 1;
}
.onepage-button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  font-size: 2rem;
  width: 3.5rem;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.currentCategory {
  background: #00a29c;
  display: table;
  z-index: 1;
  margin: 4px 0;
  padding: 17px 20px;
  text-align: center;
  width: 100%;
  border-radius: 8px;
}
.progress-section {
  background-color: $primary-color !important;
  font-family: $font-text;
  text-transform: uppercase;
  .progress {
    list-style: none;
    margin: 0;
    padding: 5px 0;
    display: inline-table;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    background: none;
    font-family: $font-subtitle;
    > li.progressTitle {
      position: relative;
      display: table-cell;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.3rem;
      p {
        color: $secondary-color;
        font-size: 1rem;
      }
      &:before {
        content: attr(data-step);
        display: block;
        margin: 0 auto;
        background: #00a29c;
        width: 2.4rem;
        height: 2.4rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 100%;
        position: relative;
        z-index: 10;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        background: #00a29c;
        width: 100%;
        height: 0.3rem;
        top: 1.1rem;
        z-index: 0;
      }
      &.is-active {
        font-size: 3rem;
        font-family: $font-title;
        p {
          font-size: 1.2rem;
          position: relative;
          top: 0.7rem;
        }
        &:before {
          width: 4.5rem;
          height: 4.5rem;
          line-height: 4.5rem;
          margin-bottom: 0;
          top: 0.7rem;
        }
      }
    }
  }
}

.questionContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.navigation-buttons{
  i{
    scale: 6;
    transition: scale 0.5s;
  }
  border: none;
  background: none;
  color: $primary-color;
  margin-top: 17vh;
  &:hover, &:active, &:focus{
    background: none !important;
    color: $primary-color !important;
    box-shadow: none !important;
  }
  &:hover{
    i{
      scale: 7;
    }
    
  }
  &:active{
    i{
      scale: 5.5;
    }
  }
}

.questionContent {
  width: calc(100% - 120px);
}
.buttonContainer {
  height: 33vh;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.noDisplay {
  display: none;
}
@media (max-width: 778px) {
  .desktop {
    display: none !important;
  }
  
  .navigation-buttons{
    i{
      scale: 4;
    }
    &:hover, &:focus{
      i{
        scale: 4;
      }
    }
    &:active{
      i{
        scale: 3;
      }
    }
  }
};
</style>


<style lang="scss">
/**
 * Needed for IE8
 */
.progress__last:after {
  display: none !important;
}

/**
 * Size Extensions
 */
.progress--medium {
  font-size: 1.5em;
}

.progress--large {
  font-size: 2em;
}

</style>

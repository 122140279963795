<template>
  <b-dropdown right variant="link" no-caret class="dropdown">
    <template slot="button-content">
      <span style="width: 100px; heigth: 40px" title="Language">
        <img
          :src="'/static/icons/language.svg'"
          height="25"
        />
        <b><span class="label">{{lang}}</span></b>
        <i style="color: #6F3996; scale: 1.2;" class="fa fa-caret-down"></i>
      </span>
    </template>
    <div v-for="(l, langIdx) in availableLangs" :key="langIdx">
      <b-dropdown-item
        :class="lang == l ? 'selected' : ''"
        @click="setLangNew(l)"
      >
        <template v-if="l === 'en'">
          {{ $t("headerMenu_language_english_caption") }}
        </template>
        <template v-else-if="l === 'es'">
          {{ $t("headerMenu_language_spanish_caption") }}
        </template>
        <template v-else-if="l === 'tr'">
          {{ $t("headerMenu_language_turkish_caption") }}
        </template>
        <template v-else>
          {{ l }}
        </template>
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "LanguageSelector",
  data: function(){
    return{
      availableLangs: ['en', 'es', 'tr']
    }
  },
  methods: {
    setLangNew: function (lang) {
      this.$store.dispatch("setLangNew", lang);
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/variables.scss';

.dropdown{
  height: 40px;
  background-color: transparent !important;
}
.label{
  text-transform: uppercase;
  font-size: 1.1em;
  color: $primary-color;
  font-family: $font-subtitle-light;
  margin: 0 5px;
}


.selected {
  font-weight: bold;
}
i.icon.icon-language {
  height: 1.2em;
  width: 1.2em;
}

@media (max-width: 778px), (orientation: portrait){
  .dropdown{
    max-width:70px;
  }
  .label{
    display: none;
  }
}

</style>

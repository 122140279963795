<template>
    <header>
      <b-container style="max-width: none; padding: 0">
        <b-row class="top-bar">
          <b-col cols="4" md="4" lg="4" sm="4" class="desktop">
            <div class="logo-branding">
              <img
                  :src="'/static/images/UNPD/unpd_logo_color_' + lang + '.svg'"
                  height="140"
                  style="margin: -7px -25px 0 0"
              />
              <img
                :src="'/static/images/GES_Logo.svg'"
                height="125"
              />
            </div>
          </b-col>

          <b-col cols="8" md="8" lg="8" sm="8" class="mobile">
            <div class="logo-branding">
                <img
                    :src="'/static/images/UNPD/unpd_logo_color_' + lang + '.svg'"
                    height="90"
                    style="margin: -7px -22px 0 0"
                />
                <img
                  :src="'/static/images/GES_Logo.svg'"
                  height="78"
                />
            </div>
          </b-col>

          <b-col cols="4" md="4" lg="4" sm="4" class="desktop">
            <div class="ml-auto title">
              <h1>{{ $t("label_"+getLower(getTemplateId)+"_title")}} <span class="business" v-if="getBusiness.name">/ {{ getBusiness.name }} </span> </h1>
            </div>
          </b-col>

          <b-col class="desktop" cols="4" md="4" lg="4" sm="4" style="display: flex; justify-content: flex-end; align-items: center; padding: 25px;">
            <div class="desktop d-flex flex-row">
              <b-btn v-if="getToken" id="save-and-exit-button" variant="primary" @click="openModal()">
                <span class="desktop" v-if="getInvitationType === 'accessNumber'">
                {{ $t('label_opinionSurvey_exit_save_button')}}
                </span>
                <span v-else>
                  {{ $t('label_opinionSurvey_close_save_button')}}
                </span>

              </b-btn>
              <div class="language-selector">
                <template-language-selector style="margin-left: 0.5rem; margin-right: 0.5rem"></template-language-selector>
              </div>
            </div>
          </b-col>
          <b-col class="mobile" cols="4" md="4" lg="4" sm="4">
            <div class="mobile d-flex flex-row" style="text-align: end; padding:20px 0px;">
              <b-btn v-if="getToken" class="save-button" @click="openModal()"><i class="fa fa-floppy-o mobile"></i></b-btn>
              <template-language-selector class="language-selector"></template-language-selector>

            </div>
          </b-col>
        </b-row>

        <div class="ml-auto title mobile">
              <h1>{{ $t("label_"+getLower(getTemplateId)+"_title")}} </h1>
        </div>

        <!-- Info modal -->
        <b-modal modal-class="modalInfo" id="modalInfo" v-model="showModal" :title="$t('label_opinionSurvey_exit_confirmation')">
          <p>{{ $t('label_opinionSurvey_exit_disclaimer') }}</p>
          <div slot="modal-footer" class="w-100 d-flex justify-content-center">
            <b-btn variant="secondary" @click="closeModal()">
              {{ $t('label_opinionSurvey_exit_cancel')}}
            </b-btn>
            <b-btn variant="primary" @click="exit()"  v-if="getInvitationType === 'accessNumber'">
              {{ $t('label_opinionSurvey_exit_ok')}}
            </b-btn>
            <b-btn v-else variant="primary" @click="close()">
              {{ $t('label_opinionSurvey_close_ok')}}
            </b-btn>
          </div>
        </b-modal>
      </b-container>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Header',
    data: function () {
      return {
        labelCounter: 5,
        showModal: false
      }
    },
    computed: {
      lang () {
        return this.$i18n.locale
      },
      ...mapGetters([
        'getInvitation',
        'getToken',
        'getInvitationType',
        'getBusiness',
        'getTemplateId'
      ])
    },
    methods: {
      getLower (name) {
        return name.charAt(0).toLowerCase() + name.slice(1)
      },

      showLabels: function () {
        this.labelCounter--
        console.log(`Click the logo ${this.labelCounter} times more to see the labels`)
        if (this.labelCounter === 0) {
          console.log('To see the translations again, You just need to change the language in the language selector')
          this.labelCounter = 5
          this.$store.commit('SET_LABEL_LANG')
        }
      },
      openModal: function () {
        this.showModal = true
      },
      closeModal () {
        this.showModal = false
      },
      exit () {
        this.showModal = false
        this.$router.push('/' + this.getInvitation.snapshotId)
      },
      close () {
        this.$router.push('/send/true')
      }
    }
  }
</script>

<style lang="scss" scoped>

/* components/template/_template-header */
@import "../../assets/css/variables.scss";

#save-and-exit-button{
  margin-top: auto;
  margin-bottom: auto;
  max-height: 40px;
  z-index: 1
}


.header-image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

header {
  font-family: $font-subtitle-light;
  color: #ffffff;
  background-color: $header-accent;
  text-transform: uppercase;
  box-shadow: 3px 3px 5px #c4c4c4;
  .title{
    margin-top: 30px;
    height: 75px;
    display: flex;
    align-items:center;
    justify-content:center;
  }
  .navbar {
    font-family: $font-subtitle-light;
    height: 90px;
    font-size: 1.3rem;
    .navbar-text {
      padding-top: 2px;
    }
    .logo {
      height: 70px;
      width: 43vw;
    }
    .navbar-brand {
      width: 110px;
      background-color: #fff;
      img {
        position: absolute;
        top: 0;
        height: 175px;
        background-color: #fff;
      }
    }
    #nav_collapse {
      height: 90px;
    }
    .navbar-nav {
      height: 100%;
      li {
        padding-top: 10px;
      }
      .login {
        background-color: #e7e7ec;
      }
    }
    .fa-icon {
      position: relative;
      top: 1px;
    }
    .pnud-logo {
      margin: 0px;
    }
    .pnud-logo-sm {
      display: none;
    }
  }
}
.mobile {
  display: none !important;
  h1{
    text-align: center;
    font-size: 1.3rem;
  }
}
@media (min-width: 374px) and (max-width: 778px) , (orientation: portrait) {
  header {
    .save-button, .save-button:hover, .save-button:active, .save-button:focus{
      background: none !important;
      color: $primary-color !important;
      box-shadow: none !important;
      border: none;
      scale: 1.5;
      &:active{
        scale: 1.75;
      }
    }
    .top-bar{
      background-color: #fff;
    }
    .title{
      margin-top: 10px;
      display: none;
      height: 40px;
      text-align: center;
      background-color: #f4fafd;
    }
    .logo-branding{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 45%;
    }
    .navbar {
      height: 70px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      .navbar-brand {
        width: 0px;
        img {
          left: -15px;
          top: 0px;
          height: 70px !important;
        }
      }
      .logo {
        height: 50px;
        margin-left: 25px;
        margin-top: 10px;
      }
    }
    .desktop {
      display: none !important;
    }
  }
  .mobile {
    display: block !important;
  }
}
.icon-save {
  filter: brightness(5.5);
}
.nav-lang {
  flex-direction: row;
}
@media (max-width: 373px) {
  header {
    .save-button, .save-button:hover, .save-button:active, .save-button:focus{
      background: none !important;
      color: $primary-color !important;
      box-shadow: none !important;
      border: none;
      scale: 1.5;
      &:active{
        scale: 1.75;
      }
    }
    .top-bar{
      background-color: #fff;
    }
    .title{
      margin-top: 10px;
      display: none;
      height: 30px;
      text-align: center;
      background-color: #f4fafd;
    }
    .logo-branding{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 45%;
    }
    .navbar {
      height: 70px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      .navbar-brand {
        width: 0px;
        img {
          left: -15px;
          top: 0px;
          height: 60px !important;
        }
      }
      .logo {
        width: 155px;
        height: 50px;
        margin-left: 15px;
        margin-top: 10px;
      }
    }
  }
};
</style>

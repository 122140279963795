<template>
  <span>
    <!--<button class="tooltip-icon" :id="tooltip" v-if="$t(tooltip) && $t(tooltip) != tooltip">
      <i class="fa fa-question-circle"></i>
    </button>
    <b-tooltip :target="tooltip" :title="$t(tooltip)"></b-tooltip>
    -->

    <b-btn class="tooltip-icon" v-b-tooltip.hover :title="$t(tooltip)" v-if="$t(tooltip) && $t(tooltip) != tooltip">
      <i class="fa fa-question-circle"></i>
    </b-btn>


  </span>
</template>

<script>

export default {
  name: 'TemplateTooltip',
  props: {
    tooltip: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.tooltip-icon {
  background-color: transparent;
  border: none;
  color: inherit !important;
  padding: 0;
  position: relative;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    color: inherit !important;
    background-color: transparent !important;
  }
}
</style>

<template>
  <b-form v-on:submit.prevent="onSubmit">
    <t-preloader :loading="loading" color="#00a29c"></t-preloader>
    <b-row v-if="error">
      <b-col>
        <p class="error">{{ $t('label_opinionSurvey_error_' + error)}}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <img class="mx-auto d-block" height="300px" :src="'/static/images/UNPD/unpd_logo_color_'+lang+'.svg'">
      </b-col>
    </b-row>
  </b-form>
</template>


<script>
import { mapGetters } from 'vuex'
import * as api from '../api/api'

export default {
  instruction: 'Invitation',
  data: function () {
    return {
      loading: false,
      error: ''
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    ...mapGetters([
      'getOpinionSurvey',
      'getTemplateId'
    ])
  },
  methods: {
    onSubmit: function () {
      this.loading = true
      this.error = ''
      this.$store.dispatch('SET_TEMPLATE_ID', this.$route.query.s || 'OpinionSurvey')
      var data = {
        snapshotId: this.$route.params.snapshot_id,
        token: this.$route.params.token_invitation_mail,
        templateId: this.$route.query.s || 'OpinionSurvey'
      }
      this.$store.dispatch('INVITATIONS_GET', data).then((resp) => {
        this.loading = false
        if (resp.languageIdentifier) {
          this.$store.dispatch('setLangNew', resp.languageIdentifier)
        }
        if (resp.status === 'complete' || resp.status === 'expired') {
          this.error = resp.status
        } else if (resp.status === 'new') {
          this.loading = true
          api.apiCall({
            url: 'Surveys/createSurvey/' + this.$route.params.snapshot_id + '/' + this.$route.params.token_invitation_mail + '/' + data.templateId,
            method: 'POST',
            data: data
          }).then(() => {
            this.$router.push('/welcome')
          }).catch((err) => {
            console.log(err)
            this.loading = false
            this.error = err.response.status
          })
        } else {
          this.$router.push('/welcome')
        }
      }).catch((err) => {
        this.loading = false
        this.error = err.response.status
      })
    }
  },
  created () {
    this.$store.dispatch('CLEAR_STORE').then(() => {
      this.onSubmit()
    })
  }
}
</script>

<style lang="sass" scoped>
  /* components/template/Code.vue */
  @import "../assets/css/variables.scss"
  .error
    margin-top: 15px
    margin-bottom: 0px
    font-size: 1.2rem
    color: $primary-color
  .btn
    margin-top: 15px
</style>

<template>
  <div>
    <b-row>
      <b-col>
        <label for="code">{{ $t('label_'+getLower(getTemplateId)+'_basicInformation_disclaimer')}}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn to="/survey/answer/0" variant="secondary">{{ $t('label_opinionSurvey_start_button')}}</b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <img class="mx-auto d-block"  style="height: 300px;" :src="'/static/images/UNPD/unpd_logo_color_'+lang+'.svg'">
      </b-col>
    </b-row>
  </div>
</template>


<script>

import {mapGetters} from 'vuex'

export default {
  instruction: 'Welcome',
  data: function () {
    return {
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    ...mapGetters([
      'getTemplateId'
    ])
  },
  methods: {
    getLower (name) {
      return name.charAt(0).toLowerCase() + name.slice(1)
    }
  },
  created: async function () {
    await this.$store.dispatch('OPINION_SURVEY_GET').then(resp => {
      console.log(resp)
    }).catch(err => {
      console.log(err)
    })
    await this.$store.dispatch('SURVEY_GET_CATEGORIES').then(resp => {
      console.log(resp)
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>


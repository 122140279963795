import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

// import { state, mutations, actions } from './mutations'

import lang from './modules/lang'
import opinionSurvey from './modules/opinionSurvey'
import opinionSurveyRules from './modules/opinionSurveyRules'
import invitation from './modules/invitation'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    lang,
    opinionSurvey,
    opinionSurveyRules,
    invitation
  },
  strict: debug,
  // state,
  // mutations,
  // actions,
  plugins: [createPersistedState({
    key: window.location.hostname,
    storage: window.localStorage,
    blackList:['lang'] })] // TODO Test storage with cookies see --> https://www.npmjs.com/package/vuex-persistedstate
})

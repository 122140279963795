import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locale = 'labels'

const i18n = new VueI18n({
  /** 默认值 */
  locale,
  messages: {}
})

export default i18n

import * as api from '../../api/api'

function initialState () {
  return {
    status: {
      invitation: ''
    },
    invitation: {},
    token: '',
    templateId: ''
  }
}

const state = initialState

const getters = {
  getInvitation: state => state.invitation,
  getToken: state => state.token,
  getInvitationType: state => state.invitation.type,
  getTemplateId: state => state.templateId
}

const actions = {
  INVITATIONS_GET: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('setInvitationStatus', { status: 'pending', key: 'invitation' })
      api.apiCall({ url: 'Invitations/get/' + data.snapshotId + '/' + data.token + '/' + data.templateId })
        .then(resp => {
          commit('setInvitationStatus', { status: 'success', key: 'invitation' })
          commit('setInvitation', resp.data)
          commit('setToken', data.token)
          if (resp.data.accessNumber) {
            commit('setWasEmailed', false)
          } else {
            commit('setWasEmailed', true)
          }
          resolve(resp.data)
        })
        .catch(err => {
          commit('setInvitationStatus', { status: 'error', key: 'setInvitationStatus' })
          reject(err)
        })
    })
  },
  INVITATIONS_CLOSE: ({ commit, state, rootState }) => {
    return new Promise((resolve, reject) => {
      const templateId = rootState.invitation.invitation.templateId

      commit('setInvitationStatus', { status: 'closing', key: 'invitation' })
      api.apiCall({ url: 'Invitations/close/' + state.invitation.snapshotId + '/' + state.token + '/' + templateId, method: 'POST' })
        .then(resp => {
          commit('setInvitationStatus', { status: 'success', key: 'invitation' })
          resolve(resp.data)
        })
        .catch(err => {
          commit('setInvitationStatus', { status: 'error', key: 'invitation' })
          reject(err)
        })
    })
  },
  CLEAR_STORE: ({ commit }) => {
    return new Promise((resolve) => {
      commit('invitationReset')
      commit('opinionSurveyReset')
      resolve()
    })
  },
  SET_TEMPLATE_ID: ({ commit }, templateId) => {
    return new Promise((resolve) => {
      commit('setTemplateId', templateId)
      resolve()
    })
  }
}

const mutations = {
  setInvitationStatus: (state, data) => {
    state.status[data.key] = data.status
  },
  setInvitation: (state, invitation) => {
    state.invitation = invitation
  },
  setTemplateId: (state, templateId) => {
    state.templateId = templateId
  },
  setToken: (state, token) => {
    state.token = token
  },
  invitationReset: (state) => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

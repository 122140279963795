import * as api from '../../api/api'
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    token: localStorage.getItem('user-token') || 'no-token',
    status: {
      opinionSurvey: '',
      optionLists: '',
      business: ''
    },
    opinionSurvey: {
    },
    categories: {},
    categoriesNew: [],
    progress: [],
    optionListsStatus: '',
    optionLists: [],
    warningMessage: false,
    currentQuestion: {},
    avoidQuestion: false,
    business: {},
    fieldsType: {}
  }
}

const state = initialState

const getters = {
  getOpinionSurvey: state => state.opinionSurvey,
  getProgress: state => state.progress,
  getCategories: state => state.categories,
  getCategoriesNew: state => state.categoriesNew,
  getSteps: state => state.steps,
  getOptionLists: state => state.optionLists,
  getOptionListByName: state => optionListName => {
    return state.optionLists.find(x => x.name === optionListName).itemLists
  },
  getWarningMessage: state => state.warningMessage,
  getCurrentQuestion: state => state.currentQuestion,
  getPreviousQuestion: state => state.previousQuestion,
  getAvoidQuestion: state => state.avoidQuestion,
  getBusiness: state => state.business
}

const actions = {
  OPINION_SURVEY_GET: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      commit('setOpinionSurveyStatus', {
        status: 'pending',
        key: 'opinionSurvey'
      })
      const snapshotId = rootState.invitation.invitation.snapshotId
      const token = rootState.invitation.token
      const templateId = rootState.invitation.templateId
      api
        .apiCall({ url: 'Surveys/get/' + snapshotId + '/' + token + '/' + templateId })
        .then(resp => {
          commit('setOpinionSurveyStatus', {
            status: 'success',
            key: 'opinionSurvey'
          })
          commit('setOpinionSurvey', resp.data)
          dispatch('OPTION_LIST_GET')
          dispatch('BUSINESS_NAME')
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          commit('setOpinionSurveyStatus', {
            status: 'error',
            key: 'opinionSurvey'
          })
        })
    })
  },
  SURVEY_GET_CATEGORIES: ({ commit,  rootState }) => {
    return new Promise((resolve, reject) => {
      api
        .apiCall({ url: 'SurveyTemplates/getCategories/' + rootState.invitation.invitation.templateId })
        .then(resp => {
          commit('setSurveyCategories', resp.data.categories)
          commit('setSurveyFieldsType', resp.data.fieldsType)
          commit('setSurveyMaxSelection', resp.data.maxSelection)
          commit('setSurveySteps', resp.data.steps)
          commit('orginizeSections')
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  OPINION_SURVEY_SAVE: ({ commit, rootState }, data) => {
    commit('setOpinionSurveyStatus', { status: 'saving', key: 'opinionSurvey' })
    const snapshotId = rootState.invitation.invitation.snapshotId
    const token = rootState.invitation.token
    const url = rootState.invitation.invitation.templateId ? 'Surveys' : 'OpinionSurveys'
    return new Promise((resolve, reject) => {
      const templateId = rootState.invitation.templateId
      api.apiCall({
          url: url + '/save/' + snapshotId + '/' + token + '/' + templateId,
          method: 'POST',
          data: data
        })
        .then(resp => {
          commit('setOpinionSurveyStatus', {
            status: 'success',
            key: 'opinionSurvey'
          })
          resolve(resp)
        })
        .catch(err => {
          commit('setOpinionSurveyStatus', {
            status: 'error',
            key: 'opinionSurvey'
          })
          reject(err)
        })
    })
  },
  OPTION_LIST_GET: ({ commit, state }) => {
    if (state.optionListsStatus !== 'success') {
      commit('setOpinionSurveyStatus', {
        status: 'pending',
        key: 'optionLists'
      })
      api
        .apiCall({ url: 'OptionLists' })
        .then(resp => {
          commit('setOpinionSurveyStatus', {
            status: 'success',
            key: 'optionLists'
          })
          commit('setOptionList', resp.data)
          // resolve(resp)
        })
        .catch(err => {
          console.log(err)
          commit('setOpinionSurveyStatus', {
            status: 'error',
            key: 'optionLists'
          })
          // reject(err)
        })
    }
  },
  BUSINESS_NAME: ({ commit, state, rootState }) => {
    if (state.business !== 'success') {
      commit('setOpinionSurveyStatus', { status: 'pending', key: 'business' })
      const snapshotId = rootState.invitation.invitation.snapshotId
      api
        .apiCall({ url: '/Businesses/name/' + snapshotId })
        .then(resp => {
          commit('setOpinionSurveyStatus', {
            status: 'success',
            key: 'business'
          })
          commit('setBusiness', resp.data)
          // resolve(resp)
        })
        .catch(err => {
          console.log(err)
          commit('setOpinionSurveyStatus', { status: 'error', key: 'business' })
          // reject(err)
        })
    }
  }
}

const mutations = {
  setOpinionSurveyStatus: (state, data) => {
    state.status[data.key] = data.status
  },
  setSurveyCategories: (state, data) => {
    state.categories = data
  },
  setSurveySteps: (state, data) => {
    state.steps = data
  },
  setSurveyFieldsType: (state, data) => {
    state.fieldsType = data
  },
  setSurveyMaxSelection: (state, data) => {
    state.maxSelection = data
  },
  setOpinionSurvey: (state, resp) => {
    state.opinionSurvey = resp
  },
  setProgress: () => {},
  setToken: (state, token) => {
    localStorage.setItem('user-token', token)
    axios.defaults.headers.common['Authorization'] = token
    state.token = token
  },
  SURVEY_CLEAN: state => {
    state.status = ''
    state.selfAssessment = {}
    state.optionListsStatus = ''
    state.optionLists = []
  },
  setOptionList: (state, resp) => {
    Vue.set(state, 'optionLists', resp)
  },
  setBusiness: (state, resp) => {
    Vue.set(state, 'business', resp)
  },
  orginizeSections: state => {
    var steps = {}
    Object.keys(state.categories).map((category, index) => {
      steps[category] = index + 1
    })

    for (var item in state.opinionSurvey) {
      var split = item.split('_')
      var category = split[1]
      var number = split[2]
      if (split.length === 4) {
        state.categories[category].push({
          title: 'label_' + item,
          number,
          options: 'optionList_' + item,
          field: item,
          value: state.opinionSurvey[item],
          type: state.fieldsType[item] ||
            category === 'basicInformation' || category === 'harassment'
            ? 1
            : null,
          category: category,
          step: steps[category],
          isRequired: true
        })
      }
      if (split.length === 5) {
        state.categories[category].push({
          title: 'label_' + item,
          number,
          field: item,
          value: state.opinionSurvey[item],
          type: state.fieldsType[item] || 3,
          category: category,
          step: steps[category],
          isRequired: true
        })
      }
      if (split.length === 6) {
        var isNew = true
        var DBValue = split[0] + '_' + split[1] + '_' + split[2] + '_' + split[3] + '_' + split[4] + '_yes' + split[5]
        state.categories[category].map((s) => {
          if (number === s.number) {
            s.fieldData = [...s.fieldData, { item, value: state.opinionSurvey[item], label: item, bdValue: DBValue }]
            isNew = false
          }
        })
        if (isNew) {
          var fieldName = split[0] + '_' + split[1] + '_' + split[2] + '_' + split[3]
          state.categories[category].push({
            title: 'label_' + fieldName,
            number,
            field: fieldName,
            fieldData: [{ item, value: state.opinionSurvey[item], label: item, bdValue: DBValue }],
            type: state.fieldsType[item] || 4,
            category: category,
            step: steps[category],
            isRequired: false,
            maxSelection: state.maxSelection[fieldName]
          })
        }
      }
    }

    Object.keys(state.categories).map(category => {
      const nCategories = [...state.categories[category]]
      nCategories.map((question, qIndex) => {
        if (question.position) {
          state.categories[category].splice(qIndex, 1)
          state.categories[category].splice(question.position, 0, question)
        }
      })
    })

    state.progress = []
    state.categoriesNew = []
    for (let p in state.categories) {
      state.progress.push(p)
      state.categoriesNew.push(...state.categories[p])
    }
    state.currentQuestion = state.categoriesNew[0]
    state.currentCategory = 'basicInformation'
  },
  setWarningMessage: (state, data) => {
    state.warningMessage = data
  },
  setPreviuosQuestion: (state, data) => {
    state.previousQuestion = data
  },
  setCategoriesNew: (state, data) => {
    state.categoriesNew = data
  },
  setCurrentQuestion: (state, data) => {
    state.currentQuestion = state.categoriesNew[data.index]
  },
  setAvoidQuestion: (state, data) => {
    state.avoidQuestion = data
  },
  opinionSurveyReset: state => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  setSurvey: (state, survey) => {
    state.opinionSurvey = survey
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

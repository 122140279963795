<template>
  <div>
    <b-row>
      <b-col>
        <label for="code">{{ $t('label_opinionSurvey_intro_accessDenied')}}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <img class="mx-auto d-block" :src="'/static/images/UNPD/unpd_logo_color_'+lang+'.svg'" style="height: 300px;">
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  instruction: 'Home',
  data: function () {
    return {
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  },
  created () {
    this.$store.dispatch('CLEAR_STORE')
  }
}
</script>


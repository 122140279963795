import urlConfig from "../../url-config.json";
import {parse} from "tldts";

export const getApiUrl = (lang) => {
  return getUrlForCurrentDomain()["api"][lang ?? "en"] ??  getUrlForCurrentDomain()["api"]["en"];
};


export const getBackendHostBaseUrl = (lang) => {
  return getUrlForCurrentDomain()["api"][lang ?? "en"].replace("/api/", "");
};

export const getFrontendUrl = (lang) => {
  return getUrlForCurrentDomain()["frontEnd"][lang ?? "en"] ?? getUrlForCurrentDomain()["frontEnd"]["en"];
};


export const getSurveyUrl = (lang) => {
  return getUrlForCurrentDomain()["survey"][lang ?? 'en']
}


const getUrlForCurrentDomain = () => {
  var hostName = parse(window.location.hostname).domain;
  var found = urlConfig[hostName];
  console.log(`Using domain: ${found ? hostName : ''}`);
  return found ?? Object.values(urlConfig)[0];
};

<template>
  <section :key="questionNumber">
<div class="question" v-if="!warningMessage ">
        <p class="instruction">
          {{ $t(question.title) }} <template-tooltip :tooltip="question.title+'_toolTip'"></template-tooltip>
        </p>
<template v-if="question.type == 4">
  <div class="options">
    <template v-for="(option,optionIndex) in question.fieldData">
      <label :key="optionIndex" v-if="labelExistAndHasTranslation(option.label)">
            <input type="checkbox" :value="option.bdValue" :name="option.item" @change="updateSurveyMultiple($event,option)" :checked="(option.bdValue===option.value)" :disabled="survey.completed || (!canSelect && option.value === null)  " />
        <div class='btn' :class="survey.completed  || (!canSelect && option.value === null) ? 'completed' :'' " :for="option.name">{{ $t(option.label) }} </div>
      </label>
    </template>
  </div>
</template>
  <template v-else>
        <template v-if="question.type == 3">
          <div class="options">
                      <label>
                  <input class=manualI :name="question.field" @blur="updateSurvey" v-model="question.value" />
                </label>
          </div>
        </template>
          <template v-else>
        <template v-if="question.type != 2">
          <template v-if="question.type == 1">
            <div class="options">
              <template v-for="(option, optionIndex) in getOptionListByName(question.options)">
                <label :key="optionIndex" v-if="labelExistAndHasTranslation(option.name)">
                  <input type='radio' :value="option.name" :name="question.field" @change="updateSurvey" v-model="question.value" :disabled="survey.completed" />
                  <div class='btn' :class="survey.completed ? 'completed' :'' " :for="option.name">{{ $t(option.name) }}</div>
                </label>
              </template>
            </div>
          </template>
          <template v-else>
            <div class='vertical-align'>
              <p class="convention left">
                {{ $t("label_opinionSurvey_response_disagree")}}
                <span class="left-vertical">{{ $t("label_opinionSurvey_response_Agree") }}</span>
                <!-- Used for mobile view -->
          </p>
            <div class='agreetment-scale'>
              <template v-for="(option, key) in 5">
                <label :key="key">
                  <input type='radio' :value="key+1" :name="question.field" @change="updateSurvey" v-model="question.value" :disabled="survey.completed"/>
                  <div class='btn' :class="{ 'first': key == 0, 'last': key == 4, 'completed' : survey.completed }" >{{ key + 1}}</div>
                </label>
              </template>
            </div>
            <p class="convention right">
              {{ $t("label_opinionSurvey_response_Agree")}}
            </p>
          </div>
        </template>
      </template>
      </template>
  </template>
    </div>
  </section>
</template>


<script>

import { mapGetters } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'

export default {
  name: 'QuestionSelect',
  props: {
    // question: Object
  },
  data () {
    return {
      question: {},
      canSelect: true,
      questionNumber: parseInt(this.$route.params.question, 10)
    }
  },
  methods: {
    loadView(){
      this.questionNumber= parseInt(this.$route.params.question, 10)
      if (isNaN(this.questionNumber)) {
        this.questionNumber = 0
      }

      this.question = {...this.categoriesNew[this.questionNumber]}
      this.checkCanSelect()
    },
    labelExistAndHasTranslation (label) {
      return this.$te(label) && this.$t(label) && this.$t(label).length
    },
    checkCanSelect () {
      if (this.question) {
        let totalChecked = 0
        if (this.question.fieldData) {
          this.question.fieldData.map(data => {
            if (data.bdValue === data.value && data.value !== null) {
              totalChecked++
            }
          })
        }
        if (totalChecked >= this.question.maxSelection) {
          this.canSelect = false
        } else {
          this.canSelect = true
        }
      }
    },
    updateSurvey: function (event) {
      if (!this.survey.completed) {
        let surveyTemp = {...this.survey}
        surveyTemp[event.target.name] = event.target.value
        this.$store.commit('setSurvey', surveyTemp)

        let newCats = [...this.categoriesNew]
        newCats[this.questionNumber] = this.question
        this.$store.commit('setCategoriesNew', newCats)
      }
    },
    updateSurveyMultiple: function (event, option) {
      if (!this.survey.completed) {
        let value = null
        if (event.target.checked) {
          value = event.target.value
        }
        this.survey[event.target.name] = value
        option.value = value
        this.$store.dispatch('OPINION_SURVEY_SAVE', this.survey)
        // question.maxSelection
        this.checkCanSelect()
      }
    }
  },
  mounted () {
this.loadView()
  },
  watch:{
    '$route.params.question': function (){
      this.loadView()
    }
  },
  computed: {
    ...mapGetters({
      'getOptionListByName': 'getOptionListByName',
      'survey': 'getOpinionSurvey',
      'warningMessage': 'getWarningMessage',
      'categoriesNew': 'getCategoriesNew'
    }),
    ...mapMultiRowFields([
      'survey.categories'
    ])
  }
}
</script>

<style lang="scss" scoped>
/* components/QuestionSelect.vue*/
@import "../assets/css/variables.scss";
.question {
  font-family: $font-text;
  &.hide {
    display: none;
  }
}
.is_required {
  display: none;
  &.show {
    display: initial;
  }
}
.instruction {
  font-size: 1.5rem;
  font-family: $font-text;
  margin-bottom: 1rem;
  text-align: center;
  button {
    background: none;
    border: none;
    &:focus,
    &:active {
      outline: none;
    }
  }
}
.convention {
  font-size: 1.4rem;
  font-family: $font-text;
  text-align: left;
  padding-top: 20px;
  &.left {
    text-align: right;
  }
  .left-vertical {
    display: none;
  }
}
.options {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  input {
    display: none;
  }
  .manualI {
    font-size: 1.5rem;
    cursor: pointer;
    display: block;
    vertical-align: middle;
    border: 2px solid #00a29c;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
  }
  label {
    padding: 0;
    text-align: center;
    margin: 5px;
  }
  .btn {
    font-size: 1.5rem;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    border: 2px solid #00a29c;
    border-radius: 20px;
    white-space: pre-line;
    &.completed {
      opacity: 0.65;
      color: #333;
      background-color: #ccc;
      border-color: #333;
      cursor: not-allowed;
    }
  }
  input:checked + .btn {
    background-color: #00a29c;
    transition: all 0.3s;
    &.completed {
      opacity: 0.65;
      color: #ccc;
      background-color: #333;
      border-color: #ccc;
    }
  }
}


@media (max-width: 575.98px) {
  .question {
    min-height: calc(100vh - 230px);
    overflow: auto;
    padding: 15px;
  }
  .instruction {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .options {
    .btn {
      font-size: 1rem;
    }
    label {
      margin: 3px;
    }
  }
  .convention {
    width: 100px;
    font-size: 1.2rem;
    text-align: right;
    position: relative;
    &.right {
      display: none;
    }
    .left-vertical {
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: initial;
    }
  }
};
</style>

<style lang="scss" scoped>

.vertical-align {
  display: flex;
  vertical-align: middle;
  justify-content: center;
}

.button-link {
  text-decoration: none;
}

.agreetment-scale {
  background: rgb(46,156,154);
  background: -moz-linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
  background: linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e9c9a",endColorstr="#6f3996",GradientType=1);

  z-index: 0;
  height: 90px;
  float: none;
  margin: 0 10px;
  width: 900px;
  max-width: 100%;
  border-radius: 20px;
  display: block;
  box-shadow: 0 2px 4px -1px rgba(25, 25, 25, 0.2);
  .btn {
    font-size: 2.8rem;
    cursor: pointer;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    font-weight: 300;
    color: #FFF;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    box-shadow: inset 0 30px 20px -20px rgba(255, 255, 255, 0.15);
    transition: color 250ms cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    &:hover {
      box-shadow: inset 0 30px 30px -20px rgba(50, 50, 50, 0.02);
      transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &.completed {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:after {
      margin-left: -10px;
      display: inline-block;
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: margin 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045) 100ms, -webkit-transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition: margin 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045) 100ms, transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition: margin 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045) 100ms, transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  }
  input {
    display: none;
  }
  label {
    display: table;
    float: left;
    padding: 0;
    width: 20%;
    height: 100%;
    margin: 0;
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    &:first-child {
      border-left: none;
      border-radius: 20px 0 0 20px;
    }
    &:last-child {
      border-radius: 0 20px 20px 0;
    }
  }

  .first {
    border-radius: 20px 0 0 20px;
  }
  .last {
    border-radius: 0 20px 20px 0;
  }
  input:checked + .btn {
    color: #c5c5c5;
    text-shadow: none;
    border: 5px solid #c5c5c5;
    //background-image: linear-gradient(to top, rgba(25, 25, 25, 0) 65%, rgba(25, 25, 25, 0.08));
    //box-shadow: inset 0 10px 50px rgba(25, 25, 25, 0.08);
    transition: all 0.3s;
  }
}



@media (max-width: 575.98px){
  .agreetment-scale{
    background: rgb(46,156,154);
    background: -moz-linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
    background: linear-gradient(90deg, rgba(46,156,154,1) 0%, rgba(30,67,129,1) 50%, rgba(111,57,150,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e9c9a",endColorstr="#6f3996",GradientType=1);
    width: 50px;
    height: 350px;
    border-radius: 10px;
    margin-left: 25px;
    .btn {
      font-size: 2rem;
      padding: 0;
    }
    label {
      height: 20%;
      width: 100%;
      margin: 0;
      text-align: center;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      &:first-child {
        border-left: none;
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
    .first {
      border-radius: 10px 10px 0 0;
    }
    .last {
      border-radius: 0 0 10px 10px;
    }

    input:checked + .btn {
      color: #c5c5c5;
      text-shadow: none;
      border: 3px solid #c5c5c5;
      //background-image: linear-gradient(to top, rgba(25, 25, 25, 0) 65%, rgba(25, 25, 25, 0.08));
      //box-shadow: inset 0 10px 50px rgba(25, 25, 25, 0.08);
      transition: all 0.3s;
      position:relative;
    }

  }

}

</style>

<template>
  <b-jumbotron>
    <b-container>
          <h2> {{ $t("label_"+getLower(getTemplateId)+"_title")}} <span class="business" v-if="business">/ {{ business }}</span></h2>
          
    </b-container>
  </b-jumbotron>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TemplateJumbotron',
  props: {
    business: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters(['getTemplateId']),
  }
}
</script>

<style lang="sass" scoped>
/* components/template/_template-jumbotron.vue */
@import "../../assets/css/variables.scss"

.jumbotron
  text-align: center
  background-color: #00a29c
  color: #fff
  text-transform: uppercase
  margin-bottom: 0
  border-radius: 0px
  height: 85px
  padding: 0
  display: flex
  align-items: center
  h2
    color: #fff
    font-family: $font-title
    font-size: 20px


@media (max-width: 575.98px)
  .jumbotron
    padding: 0.5rem
    height: 50px
    h2
      font-size: 6vw
      margin: 0px
  .business
    display: none

</style>

<template>
  <div class="wrapper">
    <div class="wrapper-header">
      <template-header></template-header>
      <b-container class="base">
        <router-view></router-view>
      </b-container>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex'
export default {
  instruction: 'Base',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters(['getBusiness'])
  }
}
</script>

<style lang="sass">

@import "../assets/css/variables.scss"

.base
  margin-top: 3rem
  font-size: 1.5rem
  font-family: $font-text
  margin-bottom: 2rem
  text-align: center
  input
    height: 54px
    text-align: center
    font-size: 2rem
    border: 2px solid
    background-color: #e7e7ec
  .btn
    margin-top: 25px
    height: 50px
    border-radius: 15px
    text-transform: uppercase
    font-size: 1.4rem
    padding-right: 30px
    padding-left: 30px
  .btn:hover
    color: #fff


</style>


import * as api from '../../api/api'

function initialState () {
  return {
    rules: {}
  }
}

const state = initialState

const getters = {
  getNextQuestionOpinionSurvey: (state) => (fieldName, fieldValue) => {
    var index = -1
    var def = -1
    state.rules.map(i => {
      if (i.fieldName === fieldName) {
        Object.keys(i.rules).map(r => {
          if (r === fieldValue) {
            index = i.rules[r]
          }
          if (r === 'default') {
            def = i.rules[r]
          }
        })
      }
    })

    if (index !== -1) return index
    return def
  },
  getPreviousQuestionOpinionSurvey: (state) => (questionNumber) => {
    var index = questionNumber - 1
    var indextemp = -1
    state.rules.map(i => {
      Object.keys(i.rules).map((s) => {
        if (parseInt(i.rules[s], 10) === parseInt(questionNumber, 10)) {
          if (indextemp === -1 || indextemp > i.number) {
            indextemp = i.number
          }
        }
      })
    })
    if (indextemp !== -1) {
      return indextemp
    } else {
      return index
    }
  },
  getLastQuestionOpinionSurvey: (state) => () => {
    var index = 0
    state.rules.map(i => {
      Object.keys(i.rules).map((s) => {
        if (i.rules[s] === 'end') {
          if (index === 0 || index > i.number) {
            index = i.number
          }
        }
      })
    })
    return index
  }
}

const actions = {
  OPINION_SURVEY_RULE_GET: (
    {commit, rootState},
    categoriesNew
  ) => {
    return new Promise((resolve, reject) => {
      api
        .apiCall({url: 'SurveyTemplates/getRules/' + rootState.invitation.invitation.templateId})
        .then(resp => {
          resp.data.map(r => {
            var fieldR = null
            categoriesNew.map((s, index) => {
              if (s.field === r.fieldName) {
                fieldR = index
              }
            })
            r.number = fieldR
            Object.keys(r.rules).map(ru => {
              var field
              categoriesNew.map((s, index) => {
                if (s.field === r.rules[ru]) {
                  field = index
                }
              })
              if (field !== -1) {
                r.rules[ru] = field
              }
            })
          })
          commit('setOpinionRules', resp.data)
          console.log(resp.data)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setOpinionRules: (state, data) => {
    state.rules = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

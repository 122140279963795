import { app } from '../../main'
import axios from 'axios'
import i18n from '../../lang/lang'

const state = {
  lang: 'labels'
}

const getters = {
  getLang: state => state.lang
}

const actions = {
  async setLangNew ({ commit }, payload) {
    if (payload in i18n.messages) {
      commit('SET_LANG', payload)
    } else {
      try {
        const res = await axios.get(`/Langs/${payload}/language.json`) // Dejar esta linea para cargar los idiomas desde el endpoint
        app.$i18n.setLocaleMessage(payload, res.data)
        commit('SET_LANG', payload)
      } catch (e) {
        console.log(e)
      }
    }
  }
}

const mutations = {
  SET_LANG: (state, payload) => {
    state.lang = payload
    app.$i18n.locale = payload
  },
  SET_LABEL_LANG: (state) => {
    app.$i18n.setLocaleMessage('labels', {})
    state.lang = 'labels'
    app.$i18n.locale = 'labels'
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

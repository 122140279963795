<template>
    <div>
        <div class="progress-section">
            <h2><b>{{$t(section)}}</b></h2>
        </div>
        <div class="progress">
            <div v-bind:style="{width: getProgressPercentage}" class="progress_bar">
            </div>
        </div>
        <div class="percentage">
            <span v-bind:style="{margin: getProgressPercentage}"> {{getProgressPercentage}}</span>
        </div>
    </div>
</template>

<script>

export default {
  name: 'TemplateProgressBar',
  props: {
    section: {
      type: String,
      default: ''
    },
    currentQuestion:{
      type: Number,
      default: 0
    }
  },
  computed:{
    getProgressPercentage: function(){
      var totalQuestions = this.$store.getters.getCategoriesNew.length;
      var percentage = (this.currentQuestion * 100) / totalQuestions;
      return Math.round(percentage) + "%";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables.scss";

.progress-section {
  background-color: $primary-color !important;
  font-family: $font-text;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  height: 75px;
  }
.progress {
  width: 100%;
  height: 15px;
  background-color: #dee2ea;
  border-radius: 0px;
}

.progress .progress_bar {
  height: 100%;
  width: 6%;
  background-color: $secondary-color;
  transition: all 0.3s;
}

.percentage{
    margin-left: -15px;
    font-size: 1.1rem;
    color: $secondary-color;
}

@media (max-width: 778px) {
  h2{
    font-size: 1.1rem;
  }
  .percentage{
    display: none;
  }
  .progress {
    margin-top:-7.5px;
    height: 10px; 
  }
  .progress-section {
    height: 60px;
  }
}

</style>
<template>
  <div>
    <t-preloader :loading="loading" color="#00a29c"></t-preloader>
    <b-row v-if="!email">
      <b-col>
        <p v-if="!this.sent">{{ $t('label_opinionSurvey_thankYou_caption')}}</p>
        <p v-if="this.sent">
          <span>{{ $t('label_opinionSurvey_confirmation_caption')}}</span>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="email">
      <b-col>
        <p>
          <span>{{ $t('label_opinionSurvey_savedConfirmation_email')}}</span>
        </p>
      </b-col>
    </b-row>

    <b-row v-if="!this.sent && !email">
      <b-col>
        <b-button @click="onSubmit" variant="secondary" :disabled="survey.completed">{{ $t('label_opinionSurvey_submit_button')}}</b-button>
      </b-col>
    </b-row>
    <b-row v-if="!this.sent  && !email">
      <b-col>
        <div class="buttonContainer">
          <b-btn class="navigation-buttons" @click="onBack">
            <i class="fa fa-angle-double-left"></i>
          </b-btn>
        </div>
        </b-col>
    </b-row>
    <b-row>
      <b-col><p>{{error}}</p></b-col>
    </b-row>
  </div>
</template>


<script>

import { mapGetters } from 'vuex'

export default {
  instruction: 'Send',
  data: function () {
    return {
      sent: false,
      error: '',
      loading: false,
      email: this.$route.params.email
    }
  },
  methods: {
    onSubmit: function () {
      this.error = ''
      if (!this.survey.completed) {
        this.loading = true
        this.$store.dispatch('INVITATIONS_CLOSE').then(() => {
          this.$store.dispatch('CLEAR_STORE')
          this.sent = true
          this.loading = false
        }).catch((err) => {
          this.error = err.response.data.error.message
          this.loading = false
        })
      }
    },
    onBack: function () {
      this.$router.push('/survey/answer/' + this.getLastQuestionOpinionSurvey())
    }
  },
  computed: {
    ...mapGetters({
      'survey': 'getOpinionSurvey',
      'getLastQuestionOpinionSurvey': 'getLastQuestionOpinionSurvey',
      'getInvitationType': 'getInvitationType'
    })
  }
}
</script>


<style  lang="scss" scoped>
@import "../assets/css/variables.scss";

.navigation-buttons{
  border: none;
  background: none;
  color: $primary-color;
  scale: 5;
  margin-top: 17vh;
  transition: scale 0.75s;
  animation-name: example;
  animation-duration: 1s;
  &:hover, &:active, &:focus{
    background: none !important;
    color: $primary-color !important;
    box-shadow: none !important;
  }
  &:hover{
    scale: 6;
  }
  &:active{
    scale: 5.5;
  }
}

</style>

